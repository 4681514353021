<template>
<div class="project-modal-wrap">
    <div class="project-modal__name form-group">
        <p class="project-modal__name__label">Название</p>
        <input class="project-modal__name__input" v-model="form.name" type="text" placeholder="Название...">
    </div>

     <div class="project-modal__group form-group">
        <p class="project-modal__group__label">Группа</p>
        <div class="project-modal__group__options">
            <p :class="form.group === projectGroup.id
                    ? 'project-modal__group__option-active'
                    : 'project-modal__group__option'"
               @click="chooseGroupClick(projectGroup.id)"
               v-for="(projectGroup, index) in projectGroups"
               :key="index">
                {{ projectGroup.name }}
            </p>
             <transition name="project-detail__password-anim">
                <font-awesome-icon
                key="3"
                @click="addGroupClick()" v-if="!groupInput"
                class="project-modal__group__option-add"
                icon="fa-solid fa-circle-plus"
                />
                <font-awesome-icon
                key="4"
                @click="saveGroupClick()"
                v-else
                class="project-modal__group__option-save"
                icon="fa-solid fa-circle-check"
                />
          </transition>
            <transition name="project-detail__password-anim">
                <input class="project-modal__group__input" type="text" v-model="groupNew" v-if="groupInput" />
            </transition>
        </div>
    </div>

     <div class="project-modal__passwords form-group">
        <p class="project-modal__passwords__label">Пароли</p>
        <div class="project-modal__passwords__groups">
          <div class="project-modal__passwords__group" v-for="(password, key) in form.passwords" :key="key">
            <p class="project-modal__passwords__group__name">{{ password.name ? password.name : '&#9679; &#9679; &#9679; &#9679; &#9679;' }}</p>
          </div>
            <transition name="project-detail__password-anim">
                <font-awesome-icon
                key="3"
                @click="addPasswordClick()" v-if="!addPassword"
                class="project-modal__group__option-add"
                icon="fa-solid fa-circle-plus"
                />
                <font-awesome-icon
                key="4"
                @click="savePasswordClick()"
                v-else
                class="project-modal__group__option-save"
                icon="fa-solid fa-circle-check"
                />
          </transition>
        </div>
          <div class="project-modal__passwords__input-group">
            <transition name="project-detail__password-anim">
                <input class="project-modal__passwords__input"
                type="text"
                placeholder="Пароль..."
                v-model="password"
                v-if="addPassword" />
            </transition>
             <transition name="project-detail__password-anim">
                <input class="project-modal__passwords__input"
                type="text"
                placeholder="Название пароля..."
                v-model="passwordName"
                v-if="addPassword" />
            </transition>
          </div>
    </div>
    <div class="project-modal__save-button-wrap">
    <button class="project-modal__save-button" @click="saveProjectClick()">Сохранить</button>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'

export default {
  name: 'project-modal',
  methods: {
    ...mapActions(['reloadProjects', 'reloadProjectGroups', 'getProjectGroup']),
    addGroupClick () {
      this.groupInput = true
    },
    addPasswordClick () {
      this.addPassword = true
    },
    chooseGroupClick (group) {
      if (this.form.group === group) {
        this.form.group = ''
        this.groupInput = false
      } else {
        this.form.group = group
        this.groupInput = false
      }
    },
    savePasswordClick () {
      this.addPassword = false
      this.form.passwords.push({
        name: this.passwordName,
        password: this.password
      })
      this.password = ''
      this.passwordName = ''
    },
    saveGroupClick () {
      this.form.group = this.groupNew
      axios.post('/api/v1/project-group/create', {
        name: this.form.group
      })
        .then((response) => {
          this.groupInput = false
          this.newGroup = ''
          this.reloadProjectGroups()
          this.flashMessage('Успех')
        })
        .catch((error) => {
          console.log(error)
          this.flashMessage('Не удалось добавить группу!', 'error')
        })
    },
    saveProjectClick () {
      axios.post('/api/v1/project/create', this.form)
        .then((response) => {
          this.groupInput = false
          this.newGroup = ''
          this.form = {
            name: '',
            group: '',
            passwords: []
          }
          this.reloadProjects()
          this.reloadProjectGroups()
          this.flashMessage('Успех')
          this.$modal.hide('project-modal')
        })
        .catch((error) => {
          console.log(error)
          this.flashMessage('Не удалось добавить группу!', 'error')
        })
    },
    flashMessage (text, type = 'success') {
      this.flash(text, type, {
        timeout: 2500,
        important: true
      })
    }
  },
  computed: {
    projectGroups () {
      return this.$store.getters.getProjectGroups
    }
  },
  data () {
    return {
      form: {
        name: '',
        group: '',
        passwords: []
      },
      groupNew: '',
      groupInput: false,
      addPassword: false,
      password: '',
      passwordName: ''
    }
  }
}
</script>
