import axios from 'axios'

const state = {
  projects: [],
  currentProject: null
}

const getters = {
  getProjects: (state) => {
    return state.projects
  },
  getCurrentProject (state) {
    return state.currentProject
  }
}

const mutations = {
  setProjects (state, projects) {
    state.projects = projects
  },
  setCurrentProject (state, project) {
    state.currentProject = project
  }
}

const actions = {
  loadProjects ({ commit }, filter) {
    commit('setProjects', [])
    axios.get('/api/v1/project/search', { params: filter })
      .then((response) => {
        commit('setProjects', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  reloadProjects ({ dispatch }) {
    const filter = new URLSearchParams()
    filter.append('all', '1')
    dispatch('loadProjects', filter)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
