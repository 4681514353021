const state = {
  tasks: []
}

const getters = {
  getTasks: (state) => {
    return state.tasks
  }
}

const mutations = {
  setTasks (state, tasks) {
    state.tasks = tasks
  }
}

const actions = {}

export default {
  state,
  getters,
  actions,
  mutations
}
