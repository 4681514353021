<template>
  <div class="row justify-content-start">
    <modal :width="'70%'" :height="'auto'" :name="'note-modal-new'" :scrollable="true"  @before-close="beforeClose">
        <note-modal :newNote="true"></note-modal>
       </modal>
    <flash-message class="wFlash"></flash-message>
    <div class="col-12 cards-search">
      <div class="card-search__input-wrap">
        <input
          class="card-search__input"
          type="search"
          placeholder="Название..."
          v-model="form.name"
          :disabled="formDisabled === true"
        />
      </div>
      <div class="card-search__input-wrap">
        <input
          class="card-search__input"
          type="search"
          placeholder="Группа..."
          v-model="form.group"
          @click="groupSearch"
          v-click-outside="hideGroupSelect"
          :disabled="formDisabled === true"
        />
        <div class="card-search__select-wrap w-100" v-if="showGroupSelect">
                <div class="card-search__select__item" v-for="group in autocompleteGroups"
                     v-bind:key="group.id"
                     @click="setGroup(group.name)">
                  {{ group.name }}
                </div>
              </div>
      </div>
      <div class="card__search__checkbox-wrap">
        <input type="checkbox" class="card__search__checkbox" id="search-all" name="search-all" v-model="form.all">
        <label for="search-all">Все</label>
      </div>
       <button class="card__search-button" @click="submit">Искать</button>
       <button class="card__search-button" @click="reset">Сбросить</button>
       <div class="card__add-wrap">
        <font-awesome-icon
            class="card__add-button"
            icon="fa-solid fa-circle-plus"
            @click="createButtonClick"
          />
      </div>
    </div>
    <div class="col-4 cards" v-for="(note, index) in notes" :key="index" >
      <div class="card" @click="setCurrentNote(note)">
        <div class="card-body">
          <div class="card-subtitle" v-if="note.group">{{ note.group.name }}</div>
          <div class="card-title">
            <vue-markdown :source="note.title"></vue-markdown>
          </div>
        </div>
      </div>
       <modal :width="'70%'" :height="'auto'" :name="'note-modal-' + modalIndex(note.id)" :scrollable="true"  @before-close="beforeClose">
        <note-modal :noteId="note.id" :index="modalIndex(note.id)" :newNote="false"></note-modal>
       </modal>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import NoteModal from '../components/Modal/NoteModal'
import { TextareaAutogrowDirective } from 'vue-textarea-autogrow-directive'
import vClickOutside from 'v-click-outside'

export default {
  name: 'Main',
  components: {
    NoteModal
  },
  methods: {
    ...mapActions(['loadNotes', 'loadNoteGroups', 'updateCurrentNote']),
    modalIndex (id) {
      if (Object.keys(this.indexes).includes(id.toString()) === false) {
        this.indexes[id] = Math.random().toString(36).slice(2)
      }
      return this.indexes[id]
    },
    setCurrentNote (note, event) {
      this.$modal.show('note-modal-' + this.modalIndex(note.id))
      this.updateCurrentNote(note)
    },
    submit () {
      const filter = new URLSearchParams()
      if (this.form.all !== true) {
        if (this.form.name) {
          filter.append('query', this.form.name)
        }
        if (this.form.notification) {
          filter.append('notification', this.form.name)
        }
        if (this.form.group) {
          filter.append('group', this.form.group)
        }
        if (Array.from(filter.values()).length < 1) {
          filter.append('pinned', '1')
        }
      }
      filter.append('all', '1')
      this.loadNotes(filter)
    },
    reset () {
      if (this.form.all) {
        this.disableFilter()
      }
      this.form = {
        name: '',
        group: '',
        notification: false,
        all: false
      }
      this.submit()
    },
    beforeClose (event) {
      this.updateCurrentNote(null)
    },
    disableFilter () {
      this.formDisabled = !this.formDisabled
    },
    groupSearch () {
      this.showGroupSelect = !this.showGroupSelect
    },
    setGroup (value) {
      this.form.group = value
      this.showGroupSelect = false
    },
    hideGroupSelect (event) {
      this.showGroupSelect = false
    },
    createButtonClick () {
      this.$modal.show('note-modal-new')
    }
  },
  data () {
    return {
      indexes: {},
      openModal: null,
      formDisabled: false,
      showGroupSelect: false,
      form: {
        name: '',
        group: '',
        all: false
      }
    }
  },
  computed: {
    notes () {
      return this.$store.getters.getNotes
    },
    noteGroups () {
      return this.$store.getters.getNoteGroups
    },
    autocompleteGroups () {
      const groups = []
      this.noteGroups.forEach(item => groups.push(item))
      return groups.filter(group => group.name.toLowerCase().includes(this.form.group.toLowerCase()) !== false)
    }
  },
  created () {
    const filter = new URLSearchParams()
    const filterGroups = new URLSearchParams()
    filter.append('pinned', '1')
    filter.append('all', '1')
    filterGroups.append('all', '1')
    this.loadNotes(filter)
    this.loadNoteGroups(filterGroups)
  },
  directives: {
    autogrow: TextareaAutogrowDirective,
    clickOutside: vClickOutside.directive
  }
}
</script>

<style scoped>

</style>
