<template>
  <div class="row nav-wrap">
    <div class="col-4">
      <ul class="nav">
        <li
          :class="['nav-item', getClassIfActive(link)]"
          v-for="(link, key) in links"
          v-bind:key="key"
        >
          <a
            :class="['nav-link', 'link-hover-animation']"
            @click="changeLocation(link.path)"
            >{{ link.text }}</a
          >
        </li>
      </ul>
    </div>
    <div class="col-4 offset-4 text-right">
      <ul class="nav">
        <li class="nav-item nav-item-logout">
          <a href="" class="nav-link" @click="LogOut()">
            <font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'navbar',
  data () {
    return {
      links: [
        {
          path: '/',
          text: 'Главная',
          route: 'main'
        },
        {
          path: '/projects',
          text: 'Проекты',
          route: 'projects'
        },
        {
          path: '/zebra-group',
          text: 'Зебра',
          route: 'zebra'
        }
      ]
    }
  },
  methods: {
    ...mapActions(['LogOut']),
    changeLocation (link) {
      this.$router.push({ path: link })
    },
    getClassIfActive (link) {
      const detailRoutes = {
        projects: ['project-detail']
      }
      const route = link.route
      const isDetail =
        route in detailRoutes &&
        detailRoutes[route].includes(this.currentRouteName)

      return this.currentRouteName === route || isDetail
        ? 'nav-item-active'
        : ''
    }
  },
  computed: {
    currentRouteName () {
      return this.$route.name
    }
  },
  created () {}
}
</script>

<style></style>
