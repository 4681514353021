import axios from 'axios'

const state = {
  notes: [],
  currentNote: null
}
const getters = {
  getNotes: (state) => {
    return state.notes
  },
  getCurrentNote (state) {
    return state.currentNote
  }
}

const mutations = {
  setNotes (state, notes) {
    state.notes = notes
  },
  setCurrentNote (state, note) {
    state.currentNote = note
  },
  updateNote (state, note) {
    const fn = function (element, note) {
      if (element.id === note.id) {
        return note
      } else {
        return element
      }
    }
    state.notes = state.notes.map(ob => fn(ob, note))
  },
  dropNote (state, note) {
    const fn = function (element, note) {
      return element.id !== note
    }
    state.notes = state.notes.filter(ob => fn(ob, note))
  },
  addNote (state, note) {
    state.notes = state.notes.concat([note])
  }
}

const actions = {
  loadNotes ({ commit }, filter) {
    commit('setNotes', [])
    axios
      .get('/api/v1/note/search', { params: filter })
      .then(response => {
        commit('setNotes', response.data)
      })
      .catch(error => {
        console.log(error)
      })
  },
  putNote ({ commit }, params) {
    axios
      .post('/api/v1/note/' + params.id, params.note)
      .then(response => {
        commit('updateNote', response.data)
      })
      .catch(error => {
        console.log(error)
      })
  },
  deleteNote ({ commit }, note) {
    axios
      .delete('/api/v1/note/' + note)
      .then(response => {
        commit('dropNote', note)
      })
      .catch(error => {
        console.log(error)
      })
  },
  createNote ({ commit }, note) {
    axios
      .post('/api/v1/note/create', note)
      .then(response => {
        commit('addNote', response.data)
      })
      .catch(error => {
        console.log(error)
      })
  },
  updateCurrentNote ({ commit }, note) {
    commit('setCurrentNote', note)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
