<template>
  <div id="app">
    <div class="container">
      <navbar v-if="auth"></navbar>
      <router-view />
    </div>
  </div>
</template>
<script>
import Navbar from './components/v2/Navbar/Navbar'
import './styles/app.less'

export default {
  components: { Navbar },
  data () {
    return {
      auth: this.$store.getters.isAuthenticated
    }
  }
}

setTimeout(function () {
  document.body.className = ''
}, 500)
</script>
<style scoped></style>
