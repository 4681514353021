import Vue from 'vue'
// import store from '../store'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
import Login from '../views/Login'
import Projects from '../views/project/Projects'
import ProjectDetail from '../views/project/ProjectDetail'
import ZebraMain from '../views/ZebraGroup/ZebraMain'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main',
    component: Main,
    meta: { guest: false }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { guest: true }
  },
  {
    path: '/projects',
    name: 'projects',
    component: Projects,
    meta: { guest: false }
  },
  {
    path: '/project/:id',
    name: 'project-detail',
    component: ProjectDetail,
    meta: { guest: false }
  },
  {
    path: '/zebra-group',
    name: 'zebra',
    component: ZebraMain,
    meta: { guest: false }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

// router.beforeEach((to, from, next) => {
//   if (store.getters.isAuthenticated || to.matched.some((record) => record.meta.guest)) {
//     return next()
//   } else {
//     next({ name: 'Login' })
//   }
// })

export default router
