<template>
     <div v-if="tasks.length > 0">
              <table class="wTable">
                    <thead>
                      <tr class="wTable__th">
                        <td>Задача</td>
                        <td>Запланировано</td>
                        <td>Потрачено</td>
                        <td>Спринт</td>
                        <td>Дедлайн</td>
                      </tr>
                    </thead>
                    <tbody name="wTable" is="transition-group">
                      <template v-for="(task, key) in getSlicedDataForTables(tasks, 'showAllTasks')">
                        <tr class="wTable__tr" :key="'task-' + key">
                          <td class="">
                            <a
                              target="_blank"
                              class="wTable__project-link link-hover-animation"
                              :href="'https://tracker.yandex.ru/' + task['key']"
                              >{{ task['key'] }}</a
                            >
                          </td>
                          <td>
                            {{ task['storyPoints'] }}sp
                          </td>
                          <td>
                            <span class="time">{{ formatTimeParts(task['spent'].hours) }}</span>:{{ formatTimeParts(task['spent'].minutes) }}
                          </td>
                          <td>
                            {{ task['sprint'] ? task['sprint'] : '-' }}
                          </td>
                          <td>
                            {{ task['deadline'] ? task['deadline'] : '-' }}
                          </td>
                        </tr>
                      </template>
                      <tr class="wTable__tr" :key="999999999999998" v-if="tasks.length > tableRows">
                          <td colspan="5">
                            <div class="zebra__search-button__wrap">
                              <div class="zebra__search-button" v-if="showAllTasks === false" @click="showAllTasks = !showAllTasks">Показать всё</div>
                              <div class="zebra__search-button" v-else @click="showAllTasks = !showAllTasks">Скрыть</div>
                            </div>
                          </td>
                      </tr>
                    </tbody>
                  </table>
            </div>
            <div class="col-12" v-else>
              <p>Нет информации...</p>
          </div>
</template>

<script>

export default {
  name: 'ZebraTasksTable',
  props: {
    tasks: Array
  },
  data () {
    return {
      showAllTasks: false,
      tableRows: 5
    }
  },
  methods: {
    formatTimeParts (part) {
      const partOfTime = part.toString()

      return partOfTime.length === 1 ? '0' + partOfTime : partOfTime
    },
    getSlicedDataForTables (data, param) {
      return Object.keys(data).length > this.tableRows && this[param] === false
        ? Object.fromEntries(Object.entries(data).slice(0, 5))
        : data
    }
  }
}
</script>
