import axios from 'axios'
import router from '../../router'

const state = {}

const getters = {
  isAuthenticated: (state) => localStorage.getItem('token') !== null
}

const actions = {
  async LogIn ({ commit }, user) {
    await axios.post('api/v1/login', user)
      .then((response) => {
        commit('setToken', response.data.access_token)
      })
      .catch((error) => {
        console.log(error)
      })
  },

  LogOut ({ commit }) {
    commit('logout')
  },

  refreshToken ({ commit }) {
    axios.post('api/v1/refresh')
      .then((response) => {
        console.log(response.data)
        commit('setToken', response.data.access_token)
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

const mutations = {
  setToken (state, token) {
    localStorage.setItem('token', token)
  },
  logout (state) {
    localStorage.removeItem('token')
    router.push({ name: 'Login' })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
