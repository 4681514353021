import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap-grid.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import vClickOutside from 'v-click-outside'
import VueMarkdown from 'vue-markdown'
import VueFlashMessage from 'vue-flash-message'
import VueMeta from 'vue-meta'
import TextareaAutosize from 'vue-textarea-autosize'
import VModal from 'vue-js-modal'
import VueTextareaAutogrowDirective from 'vue-textarea-autogrow-directive'
import axiosSetUp from './utils/axios'
import VueDatepickerUi from 'vue-datepicker-ui'
import 'vue-datepicker-ui/lib/vuedatepickerui.css'

axiosSetUp()
library.add(fas)
Vue.config.productionTip = false
Vue.filter('truncate', function (text, length, suffix) {
  if (text.length > length) {
    return text.substring(0, length) + suffix
  } else {
    return text || ''
  }
})

Vue.use(TextareaAutosize)
Vue.use(VueMeta)
Vue.use(VModal)
Vue.use(VueMarkdown)
Vue.use(VueTextareaAutogrowDirective)
Vue.use(VueFlashMessage)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('vue-markdown', VueMarkdown)
Vue.use(vClickOutside)
Vue.component('Datepicker', VueDatepickerUi)
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
