<template>
  <div class="">
    <modal :width="'70%'" :height="'auto'" name="project-modal" :scrollable="true">
      <project-modal></project-modal>
    </modal>
    <div class="row text-center projects-search">
      <flash-message class="wFlash"></flash-message>
      <div class="col-4">
        <input
          class="projects-search__input"
          type="text"
          placeholder="Проект или группа..."
          v-model="searchInput"
        />
      </div>
      <div class="col-4 projects__add-wrap">
        <font-awesome-icon
            class="projects__add-button"
            icon="fa-solid fa-circle-plus"
            @click="createButtonClick"
          />
      </div>
    </div>
  <div class="row text-center projects-wrap">
        <div key="1" class="projects-table col-12">
          <table class="wTable">
            <thead>
              <tr class="wTable__th">
                <td>Название</td>
                <td>Группа</td>
                <td>Пароли</td>
                <td>Обновлён</td>
                <td>Действия</td>
              </tr>
            </thead>
            <tbody name="wTable" is="transition-group">
              <template v-for="(project, key) in search">
                <tr class="wTable__tr" :key="key">
                  <td class="">
                    <a
                      target="_blank"
                      class="wTable__project-link link-hover-animation"
                      :href="'/project/' + project.id + '/'"
                      >{{ project.name }}</a
                    >
                  </td>
                  <td>{{ project.group ? project.group.name : '-' }}</td>
                  <td v-if="project.passwords.length > 0">
                    <p
                      class="wTable__password"
                      @click="copyPassword(password.password)"
                      v-for="(password, index) in project.passwords"
                      :key="index"
                    >
                      {{ password.name ? password.name : '&#9679; &#9679; &#9679; &#9679; &#9679;' }}
                    </p>
                  </td>
                  <td v-else>-</td>
                  <td>
                    {{ formatDate(project.updated_at) }}
                  </td>
                  <td>
                    <font-awesome-icon
                      @click="deleteProjectClick(project.id)"
                      class="projects__action projects__action__delete"
                      icon="fa-solid fa-trash-can" />
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
    </div>
  </div>
</template>
<style lang="less">
</style>
<script>
import { mapActions } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import ProjectModal from './components/ProjectModal.vue'

export default {
  name: 'projects',
  methods: {
    ...mapActions(['reloadProjects', 'reloadProjectGroups', 'loadProjects']),
    formatDate (date) {
      return date ? moment.unix(date).format('YYYY-MM-DD h:mm') : '-'
    },
    copyPassword (password) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(password)
        require('vue-flash-message/dist/vue-flash-message.min.css')
        this.flash('Скопировано', 'success', {
          timeout: 2500,
          important: true
        })
      }
    },
    createButtonClick () {
      this.$modal.show('project-modal')
    },
    deleteProjectClick (id) {
      axios.delete('/api/v1/project/' + id)
        .then((response) => {
          this.reloadProjects()
          this.flashMessage('Успех')
        })
        .catch((error) => {
          console.log(error)
          this.flashMessage('Не удалось удалить проект!', 'error')
        })
    },
    flashMessage (text, type = 'success') {
      this.flash(text, type, {
        timeout: 2500,
        important: true
      })
    }
  },
  data () {
    return {
      searchInput: ''
    }
  },
  created () {
    const filter = new URLSearchParams()
    filter.append('all', 1)
    this.loadProjects(filter)
    this.reloadProjectGroups()
  },
  computed: {
    projects () {
      return this.$store.getters.getProjects ? this.$store.getters.getProjects : []
    },
    search () {
      const items = []
      const _this = this
      this.projects.forEach((item) => items.push(item))
      return items.filter(function (item) {
        const input = _this.searchInput.toLowerCase()
        const projectMatch = item.name.toLowerCase().includes(input) !== false
        const groupMatch = item.group
          ? item.group.name.toLowerCase().includes(input) !== false
          : false
        return projectMatch || groupMatch
      })
    }
  },
  metaInfo () {
    return {
      title: 'Проекты'
    }
  },
  components: { ProjectModal }
}
</script>

<style></style>
