<template>
    <div class="row justify-content-start">
        <flash-message class="wFlash"></flash-message>
        <modal :width="'90%'" :height="'auto'" name="zebra-tasks-modal" class="zebra-tasks-modal" :scrollable="true">
          <zebra-tasks-modal :member="getMember()"></zebra-tasks-modal>
        </modal>
        <div class="col-12">
          <div class="zebra__choose-member__wrap" @click="showTeamSelect = !showTeamSelect">
            <div class="zebra__choose-member__name">
              <span class="zebra__choose-member__name__text">
                {{ getMember().realName }}
              </span>
              <span class="zebra__choose-member__name__icon">
                <font-awesome-icon icon="fa-solid fa-chevron-down" v-if="showTeamSelect === false" />
                <font-awesome-icon icon="fa-solid fa-chevron-up" v-else />
              </span>
              <div class="card-search__select-wrap" v-if="showTeamSelect"
                v-click-outside="hideTeamSelect">
                <div class="card-search__select__item" v-for="member in team"
                     v-bind:key="'member-' + member.id"
                     @click="setCurrentTeamMember(member.id)">
                  {{ member.realName }}
                </div>
              </div>
            </div>
            <div class="lds-ring" v-if="requestLoading"><div></div><div></div><div></div><div></div></div>
          </div>
        </div>
        <div class="col-12 zebra__track-info-overall__wrap">
          <p class="zebra__track-info__header">Трек времени</p>
          <div class="zebra__track-info__body row" v-if="Object.keys(trackInfo).length > 0">
            <div class="zebra__track-info__body__group col-12">
              <div class="zebra__track-info-overall__time-wrap">
                <p class="zebra__track-info-overall__time">
                  {{ trackInfo.today.overall.hours }}<span class="zebra__track-info-overall__time__letter">ч</span> {{ trackInfo.today.overall.minutes }}<span class="zebra__track-info-overall__time__letter">м</span>
                </p>
                <span class="zebra__track-info-overall__time-sub">Общее</span>
              </div>
              <div class="zebra__track-info-overall__time-wrap">
                <p class="zebra__track-info-overall__time">
                  {{ trackInfo.today.client.hours }}<span class="zebra__track-info-overall__time__letter">ч</span> {{ trackInfo.today.client.minutes }}<span class="zebra__track-info-overall__time__letter">м</span>
                </p>
                <span class="zebra__track-info-overall__time-sub">Клиентское</span>
              </div>
              <div class="zebra__search-button__wrap">
                <div class="zebra__search-button" @click="openTasksModal()">Трек по задачам</div>
              </div>
            </div>
          </div>
          <div class="col-12" v-else>
              <p>Нет информации по треку...</p>
          </div>
        </div>
        <div class="col-12 zebra__tasks-info__wrap">
          <div key="2" class="zebra__tasks-info col-12" >
            <p class="zebra__tasks-info__header">Задачи</p>
            <div class="col-12">
              <div class="zebra__tasks-info__tabs-wrap">
                <p
                :class="['zebra__tasks-info__tabs__item', tab === link.type ? 'zebra__tasks-info__tabs__item-active' : '']"
                v-for="(link, key) in tabs" :key="key">
                  <span @click="changeTab(link)">{{ link.name }}</span>
                  <span v-if="link.type in tasksInfo"> ({{ tasksInfo[link.type]['count'] }})</span>
                </p>
              </div>
            </div>
            <div class="zebra__tasks-info__inProgess" v-if="tab === 'inProgress'">
              <zebra-tasks-table :tasks="getTasksByType('inProgress')"></zebra-tasks-table>
            </div>
            <div class="zebra__tasks-info__open" v-if="tab === 'open'">
              <zebra-tasks-table :tasks="getTasksByType('open')"></zebra-tasks-table>
            </div>
            <div class="zebra__tasks-info__reopen" v-if="tab === 'reopen'">
              <zebra-tasks-table :tasks="getTasksByType('reopen')"></zebra-tasks-table>
            </div>
            <div class="zebra__tasks-info__inReview" v-if="tab === 'inReview'">
              <zebra-tasks-table :tasks="getTasksByType('inReview')"></zebra-tasks-table>
            </div>
            <div class="zebra__tasks-info__resolved" v-if="tab === 'resolved'">
              <zebra-tasks-table :tasks="getTasksByType('resolved')"></zebra-tasks-table>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'
import vClickOutside from 'v-click-outside'
import ZebraTasksModal from './ZebraTasksModal.vue'
import ZebraTasksTable from './components/ZebraTasksTable.vue'
import moment from 'moment'

export default {
  name: 'ZebraMain',
  data () {
    return {
      team: [
        {
          id: 0,
          username: 'd.hovzun',
          realName: 'Данила Ховзун'
        },
        {
          id: 1,
          username: 'd.fomin',
          realName: 'Дмитрий Фомин'
        },
        {
          id: 2,
          username: 'd.kolesnikov ',
          realName: 'Дмитрий Колесников'
        }
      ],
      showTeamSelect: false,
      activeTeamMember: 0,
      showPeriodSelect: false,
      activePeriod: 0,
      trackInfo: {},
      tasksInfo: {},
      showAllTrackTasks: false,
      requestLoading: false,
      tabs: [
        {
          type: 'inProgress',
          name: 'В работе'
        },
        {
          type: 'reopen',
          name: 'Переоткрыто'
        },
        {
          type: 'resolved',
          name: 'Решено'
        },
        {
          type: 'inReview',
          name: 'Ревью'
        },
        {
          type: 'open',
          name: 'Открыто'
        }
      ],
      tab: 'inProgress'
    }
  },
  methods: {
    ...mapActions(['setTasks']),
    openTasksModal () {
      this.$modal.show('zebra-tasks-modal')
    },
    getMember () {
      return this.team[this.activeTeamMember]
    },
    getTasksByType (type) {
      return type in this.tasksInfo
        ? this.tasksInfo[type].items
        : []
    },
    setCurrentTeamMember (memberId) {
      this.activeTeamMember = memberId
      this.loadTrackInfo()
      this.loadTasksInfo()
    },
    hideTeamSelect () {
      this.showTeamSelect = false
    },
    formatTimeParts (part) {
      const partOfTime = part.toString()

      return partOfTime.length === 1 ? '0' + partOfTime : partOfTime
    },
    loadTrackInfo () {
      const member = this.getMember()
      const $this = this
      this.requestLoading = true

      axios.get('/api/v1/yandex-tracker/track-info',
        {
          params: {
            member: member.username,
            from: moment().startOf('isoWeek').unix(),
            to: moment().endOf('isoWeek').unix()
          }
        }
      )
        .then((response) => {
          this.trackInfo = response.data
          $this.$store.commit('setTasks', this.trackInfo.tasks)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(function () {
          $this.requestLoading = false
        })
    },
    loadTasksInfo () {
      const member = this.getMember()
      this.requestLoading = true
      const $this = this
      axios.get('/api/v1/yandex-tracker/tasks-info',
        {
          params: {
            member: member.realName
          }
        }
      )
        .then((response) => {
          this.tasksInfo = response.data
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(function () {
          $this.requestLoading = false
        })
    },
    changeTab (tab) {
      this.tab = tab.type
    }
  },
  computed: {
    tasks () {
      return Object.keys(this.trackInfo).length > 0 ? this.trackInfo.tasks : []
    },
    modalTasks () {
      return this.loadTasksInfo()
    }
  },
  mounted () {
    this.loadTrackInfo()
    this.loadTasksInfo()
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: { ZebraTasksModal, ZebraTasksTable },
  metaInfo () {
    return {
      title: 'Зебра'
    }
  }
}
</script>
