import axios from 'axios'

const state = {
  projectGroups: []
}

const getters = {
  getProjectGroups: (state) => {
    return state.projectGroups
  }
}

const actions = {
  loadProjectGroups ({ commit }, filter = {}) {
    axios
      .get('/api/v1/project-group/search', { params: filter })
      .then(response => {
        commit('setProjectGroups', response.data)
      })
      .catch(error => {
        console.log(error)
      })
  },
  reloadProjectGroups ({ dispatch }) {
    const filterGroups = new URLSearchParams()
    filterGroups.append('all', '1')
    dispatch('loadProjectGroups', filterGroups)
  },
  async putProjectGroup ({ commit }, params) {
    await axios
      .post('/api/v1/project-group/' + params.id, params.note)
      .then(response => {
        commit('updateProjectGroup', response.data)
      })
      .catch(error => {
        console.log(error)
      })
  },
  getProjectGroup: ({ getters }, id) => {
    return getters.getProjectGroups.filter(item => item.id === id || item.name === id)[0]
  }
}

const mutations = {
  setProjectGroups (state, projectGroups) {
    state.projectGroups = projectGroups
  },
  updateProjectGroup (state, projectGroups) {
    const fn = function (element, projectGroups) {
      if (element.id === projectGroups.id) {
        return projectGroups
      } else {
        return element
      }
    }
    state.projectGroups = state.projectGroups.map(ob => fn(ob, projectGroups))
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
