import axios from 'axios'

const state = {
  noteGroups: []
}

const getters = {
  getNoteGroups: (state) => {
    return state.noteGroups
  },
  getNoteGroup: (state, id) => {
    return state.noteGroups.filter(item => item.id === id)[0]
  }
}

const mutations = {
  setNoteGroups (state, noteGroups) {
    state.noteGroups = noteGroups
  }
  // updateNote (state, noteGroups) {
  //   const fn = function (element, noteGroups) {
  //     if (element.id === noteGroups.id) {
  //       return noteGroups
  //     } else {
  //       return element
  //     }
  //   }
  //   state.noteGroups = state.noteGroups.map(ob => fn(ob, noteGroups))
  // }
}

const actions = {
  async loadNoteGroups ({ commit }, filter = {}) {
    await axios
      .get('/api/v1/note-group/search', { params: filter })
      .then(response => {
        commit('setNoteGroups', response.data)
      })
      .catch(error => {
        console.log(error)
      })
  },
  async putNoteGroup ({ commit }, params) {
    await axios
      .post('/api/v1/note-group/' + params.id, params.note)
      .then(response => {
        commit('updateNoteGroup', response.data)
      })
      .catch(error => {
        console.log(error)
      })
  },
  updateNoteGroups ({ commit }, noteGroups) {
    commit('setNoteGroups', noteGroups)
  },
  noteGroupToTop ({ commit, getters }, noteGroup) {
    let data = getters.getNoteGroups
    data = data.filter(item => (item.name !== noteGroup.name) || (item.id === noteGroup.id))
    data.unshift(noteGroup)
    const result = [...new Set(data)]
    commit('setNoteGroups', result)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
