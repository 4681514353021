import axios from 'axios'
import store from '../store'
import router from '../router'

export default function axiosSetUp () {
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE

  axios.interceptors.request.use(
    function (config) {
      const token = localStorage.getItem('token')

      if (token) {
        config.headers.Authorization = 'Bearer ' + token
      }
      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  axios.interceptors.response.use(
    function (response) {
      return response
    },
    async function (error) {
      const originalRequest = error.config
      if (
        error.response.status === 401 &&
        originalRequest.url.includes('api/v1/refresh')
      ) {
        store.commit('LogOut')
        router.push({ name: 'Login' })
        return Promise.reject(error)
      } else if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true
        await store.dispatch('refreshToken')
        return axios(originalRequest)
      }
      return Promise.reject(error)
    }
  )
}
