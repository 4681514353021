import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import notes from './modules/notes'
import noteGroups from './modules/noteGroups'
import projects from './modules/projects'
import projectGroups from './modules/projectGroups'
import tasks from './modules/zebraTasks'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    notes,
    noteGroups,
    projects,
    projectGroups,
    tasks
  }
})
