<template>
  <div class="row min-vh-100 login-wrap">
    <div class="col-4 login">
      <form @submit.prevent="submit">
        <div class="login__username">
          <input type="text" class="form-control" id="identifier" v-model="form.identifier" placeholder="Имя пользователя...">
        </div>
        <div class="login__password">
          <input type="password" class="form-control" id="password" v-model="form.password" placeholder="Пароль...">
        </div>
        <div class="login__submit">
          <button type="submit" class="btn login__submit">Вход</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Login',
  data () {
    return {
      form: {
        identifier: '',
        password: ''
      },
      showError: false
    }
  },
  methods: {
    ...mapActions(['LogIn']),
    async submit () {
      const User = new FormData()
      User.append('identifier', this.form.identifier)
      User.append('password', this.form.password)
      this
        .LogIn(User)
        .then(() => {
          this.showError = false
          window.location = '/'
        })
        // eslint-disable-next-line handle-callback-err
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>

<style scoped>

</style>
