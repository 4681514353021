<template>
  <div class="row">
    <flash-message class="wFlash"></flash-message>
    <div class="col-12 project-detail__header-wrap">
      <div class="project-detail__title-wrap">
        <h1 key="1" v-if="!titleEdit" class="project-detail__title">
          {{ project.name }}
        </h1>
        <input
          key="2"
          class="project-detail__title__input"
          v-model="project.name"
          v-else
          type="text"
        />
         <!-- <div class="project-detail__subtitle">
          {{ project.group ? project.group.name : '' }}
        </div> -->
        <div
          :class="[
            titleEdit
              ? 'project-detail__title__border-active'
              : 'project-detail__title__border'
          ]"
        ></div>
      </div>
      <font-awesome-icon
        v-if="titleEdit === false"
        @click="titleEdit = !titleEdit"
        class="project-detail__title__edit"
        icon="fa-solid fa-pencil"
      />
      <font-awesome-icon
        v-else
        @click="saveTitleClick(project.id, project.name)"
        class="project-detail__title__save"
        icon="fa-solid fa-circle-check"
      />
    </div>
    <div class="col-12">
      <div class="project-detail__sidebar-wrap">
        <p
        :class="['project-detail__sidebar__item', tab === link.type ? 'project-detail__sidebar__item-active' : '']"
        v-for="(link, key) in sidebar" :key="key">
          {{ link.name }}
        </p>
        <p class="project-detail__sidebar__button project-detail__sidebar__button__add">
          <font-awesome-icon
            v-if="!editPassword  && !creatingPassword"
            class="project-detail__passwords__add-button"
            icon="fa-solid fa-circle-plus"
            @click="addPasswordClick"
          />
          <font-awesome-icon
            v-else
            class="project-detail__passwords__save-button"
            icon="fa-solid fa-circle-check"
            @click="savePasswordClick"
          />
        </p>
      </div>
    </div>
    <div class="col-12">
      <div class="project-detail__content-wrap">
        <div class="project-detail__passwords-wrap" v-if="tab === 'passwords'">
        <table class="wTable">
            <thead>
              <tr class="wTable__th">
                <td>Название</td>
                <td>Пароль</td>
                <td>Действия</td>
              </tr>
            </thead>
            <tbody name="wTable" is="transition-group"></tbody>
            <tr class="wTable__tr" v-for="(password, key) in project.passwords" :key="key">
              <td v-if="editPassword !== 'edit-' + key">{{ password.name }}</td>
              <td v-else>
                <input
                  class="project-modal__name__input"
                  type="text"
                  placeholder="Название..."
                  v-model="project.passwords[key].name"
                />
              </td>
              <td v-if="editPassword !== 'edit-' + key">&#9679; &#9679; &#9679; &#9679; &#9679;</td>
               <td v-else>
                <input
                  class="project-modal__name__input"
                  type="text"
                  placeholder="Пароль..."
                  v-model="project.passwords[key].password"
                />
              </td>
              <td>
                 <font-awesome-icon
                    v-if="editPassword !== 'edit-' + key"
                    @click="editPassword = 'edit-' + key"
                    class="projects__action projects__action__edit"
                    icon="fa-solid fa-pencil" />
                  <font-awesome-icon
                    v-else-if="editPassword === 'edit-' + key"
                    class="projects__action projects__action__save"
                    icon="fa-solid fa-circle-check"
                    @click="savePasswordClick"
                  />
                 <font-awesome-icon
                      class="projects__action projects__action__delete"
                      icon="fa-solid fa-trash-can"
                      @click="deletePasswordClick(password)"
                      />
              </td>
            </tr>
            </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'project-detail',
  data () {
    return {
      project: {},
      editPassword: false,
      creatingPassword: false,
      titleEdit: false,
      desriptionEdit: false,
      sidebar: [
        {
          type: 'passwords',
          name: 'Пароли'
        },
        {
          type: 'description',
          name: 'Описание'
        }
      ],
      tab: 'passwords'
    }
  },
  created () {
    require('vue-flash-message/dist/vue-flash-message.min.css')

    const id = this.$route.params.id
    axios.get('/api/v1/project/' + id)
      .then((response) => {
        this.project = response.data
      })
      .catch((error) => {
        console.log(error)
      })
  },
  methods: {
    editPasswordClick (index) {
      const key = 'edit-' + parseInt(index)
      this.passwordEdit = key
    },
    savePasswordClick (password = null) {
      const id = this.project.id
      const passwords = this.project.passwords
      passwords.forEach(password => {
        return Object.keys(password).forEach(key => {
          if (!['id', 'name', 'password'].includes(key)) {
            delete password[key]
          }
        })
      })
      axios.post('/api/v1/project/' + id, { passwords })
        .then((response) => {
          this.editPassword = false
          this.project = response.data
          this.creatingPassword = false
          this.flashMessage('Успех')
        })
        .catch((error) => {
          console.log(error)
          this.flashMessage('Ошибка обновления!', 'error')
        })
    },
    saveDescriptionClick (project, description) {
      this.desriptionEdit = false
    },
    saveTitleClick (project, title) {
      axios.post('/api/v1/project/' + project, {
        name: title
      })
        .then((response) => {
          this.titleEdit = false
          this.project = response.data
          this.flashMessage('Успех')
        })
        .catch((error) => {
          console.log(error)
          this.flashMessage('Ошибка обновления!', 'error')
        })
    },
    deletePasswordClick (password) {
      const id = this.project.id
      if (password.id !== null) {
        const url = `/api/v1/project/${id}/delete-password/`
        axios.post(url, {
          password: password.password
        })
          .then((response) => {
            this.project = response.data
            this.flashMessage('Успех')
          })
          .catch((error) => {
            console.log(error)
            this.flashMessage('Ошибка удаления пароля!', 'error')
          })
      } else {
        this.project.passwords.pop()
        this.creatingPassword = false
        this.editPassword = false
      }
    },
    addPasswordClick () {
      if (this.creatingPassword) {
        return
      }

      this.creatingPassword = true
      this.editPassword = 'edit-' + this.project.passwords.length
      this.project.passwords.push({
        password: '',
        id: null
      })
      const index = this.project.passwords.length - 1
      const key = 'edit-' + parseInt(index)
      this.passwordEdit = key
    },
    copyPassword (password) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(password)
        this.flashMessage('Скопировано')
      }
    },
    flashMessage (text, type = 'success') {
      this.flash(text, type, {
        timeout: 2500,
        important: true
      })
    },
    goToTasksPage () {
      this.flashMessage('Страницы пока что нет')
    }
  },
  metaInfo () {
    return {
      title: this.project.name
    }
  }
}
</script>
