<template>
    <div class="note-modal-wrap"  :id="'notes-modal-' + index">
      <div class="note-modal__name form-group">
        <input class="note-modal__name__input" v-model="form.title" v-if="edit" type="text" placeholder="Название...">
        <p v-else>{{ form.title }}</p>
      </div>
      <div class="note-modal__description form-group">
        <textarea-autosize
                :id="'note-description-' + index"
                class="note-modal__description__textarea"
                v-model="form.description"
                role="textbox"
                v-if="edit"
                ></textarea-autosize>
        <vue-markdown :source="form.description ? form.description : ''" v-else></vue-markdown>
      </div>
      <div class="note-modal__checkbox-wrap form-group">
        <input type="checkbox" class="note-modal__checkbox" id="note-pinned" name="note-pinned" v-model="form.pinned">
        <label for="note-pinned">Закрепить</label>
      </div>
      <div class="note-modal__group">
        <div class="note-modal__group__options">
            <p :class="getGroupClass(noteGroupOption.id)"
               v-for="(noteGroupOption, groupKey) in getNoteGroups()"
               :key="groupKey"
               @click="setActiveGroupModal(noteGroupOption)"
               >
                {{ noteGroupOption.name }}
            </p>
          </div>
          <div class="note-modal__group__expand">
            <font-awesome-icon
            icon="fa-solid fa-chevron-down"
            @click="noteGroupFull = true"
            v-if="!noteGroupFull"
            class="note-modal__group__expand__toggle"
            />
            <font-awesome-icon
            icon="fa-solid fa-chevron-up"
            @click="noteGroupFull = false"
            v-if="noteGroupFull"
            class="note-modal__group__expand__toggle"
            />
          </div>
      </div>
      <div class="note-modal__buttons">
        <button class="note-modal__buttons__button" @click="saveNoteClick()">Сохранить</button>
        <button class="note-modal__buttons__button" @click="editMode=!editMode" v-if="!editMode">Редактировать</button>
        <button class="note-modal__buttons__button" @click="editMode=!editMode" v-else>Смотреть</button>
        <button class="note-modal__buttons__button" @click="deleteNoteClick()" v-if="newNote === false">Удалить</button>
      </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'NoteModal',
  props: {
    noteId: Number,
    index: String,
    newNote: Boolean
  },
  computed: {
    noteGroups () {
      return this.$store.getters.getNoteGroups
    },
    note () {
      return this.$store.getters.getCurrentNote
    },
    noteGroup: {
      get () {
        return this.note && this.note.group ? this.note.group : null
      },
      set (value) {
        if (this.note && this.note.group) {
          this.note.group = null
        } else {
          this.note.group = { name: value }
        }
      }
    },
    edit () {
      return this.editMode || this.newNote
    }
  },
  methods: {
    ...mapActions(['putNote', 'deleteNote', 'loadNotes', 'noteGroupToTop', 'createNote']),
    setActiveGroupModal (group) {
      this.form.group_id = group.id
      this.noteGroupToTop(group)
    },
    getGroupClass (group) {
      return this.form.group_id === group
        ? 'note-modal__group__option-active'
        : 'note-modal__group__option'
    },
    getNoteGroups () {
      const data = this.$store.getters.getNoteGroups
      const result = this.noteGroupFull ? data : data.slice(0, 5)

      return result
    },
    saveNoteClick () {
      if (this.newNote) {
        this.createNewNote()
      } else {
        this.updateNote()
      }
    },
    createNewNote () {
      const data = {}

      Object.keys(this.form).forEach(key => {
        if (this.form[key]) {
          data[key] = this.form[key]
        }
      })
      this
        .createNote(data)
        .then((resposne) => {
          this.form = {
            title: '',
            description: '',
            group_id: '',
            pinned: false
          }
          this.$modal.hide('note-modal-new')
          this.flashMessage('Успех')
        })
        .catch((error) => {
          console.log(error)
          this.flashMessage('Не удалось создать заметку!', 'error')
        })
    },
    updateNote () {
      this
        .putNote({ note: this.form, id: this.note.id })
        .then((resposne) => {
          this.flashMessage('Успех')
          this.$modal.hide('note-modal-' + this.index)
        })
        .catch((error) => {
          console.log(error)
          this.flashMessage('Не удалось обновить заметку!', 'error')
        })
    },
    deleteNoteClick () {
      this
        .deleteNote(this.note.id)
        .then((resposne) => {
          this.form = {
            title: '',
            description: '',
            group_id: '',
            pinned: false
          }
          this.flashMessage('Успех')
          this.$modal.hide('note-modal-' + this.index)
        })
        .catch((error) => {
          console.log(error)
          this.flashMessage('Не удалось уничтожить заметку!', 'error')
        })
    },
    flashMessage (text, type = 'success') {
      this.flash(text, type, {
        timeout: 2500,
        important: true
      })
    }
  },
  data () {
    const note = this.$store.getters.getCurrentNote
    return {
      form: {
        title: note && !this.new ? note.title : '',
        description: note && !this.new ? note.description : '',
        group_id: note && !this.new ? note.group_id : '',
        pinned: note && !this.new ? note.pinned : ''
      },
      editMode: false,
      noteGroupFull: false
    }
  },
  mounted () {
    this.$nextTick(function () {
      if (this.noteId) {
        const filtered = this.$store.getters.getNotes.filter(item => item.id === this.noteId)
        const note = filtered.length > 0 ? filtered[0] : {}
        this.$store.commit('setCurrentNote', note)
      }
    })
  },
  created () {
    require('vue-flash-message/dist/vue-flash-message.min.css')
  }
}
</script>
