<template>
    <div class="row zebra-tasks-modal">
      <div class="col-3 zebra-tasks-modal-search__sidebar">
        <div class="zebra-tasks-modal__track-info">
          <p class="zebra-tasks-modal-search__header">Время</p>
          <div class="zebra-tasks-modal__track-info__time">
            <div class="zebra-tasks-modal__track-info__time-wrap">
              <p class="zebra__track-info-overall__time">
                {{ time.overall.hours }}<span class="zebra__track-info-overall__time__letter">ч</span> {{ time.overall.minutes }}<span class="zebra__track-info-overall__time__letter">м</span>
              </p>
              <span class="zebra__track-info-overall__time-sub">Общее</span>
            </div>
            <div class="zebra-tasks-modal__track-info__time-wrap">
              <p class="zebra__track-info-overall__time">
                {{ time.client.hours }}<span class="zebra__track-info-overall__time__letter">ч</span> {{ time.client.minutes }}<span class="zebra__track-info-overall__time__letter">м</span>
              </p>
              <span class="zebra__track-info-overall__time-sub">Клиентское</span>
            </div>
          </div>
        </div>
        <div class="zebra-tasks-modal-search">
          <p class="zebra-tasks-modal-search__header">Фильтры</p>
          <div class="zebra-tasks-modal-search__inputs">
            <input class="zebra-tasks-modal-search__input" type="text" placeholder="задача..." v-model="form.task" />
            <input class="zebra-tasks-modal-search__input" type="text" placeholder="коммент..." v-model="form.comment" />
            <div class="zebra-tasks-modal-search__datepicker-wrap">
              <Datepicker
                range
                v-model="form.dateRange"
                class="zebra-tasks-modal-search__datepicker"
                lang="ru"
              />
            </div>
            <div class="zebra__search-button zebra-tasks-modal-search__button" @click="searchTasks()">
              Поиск
            </div>
            <div class="lds-ring" v-if="requestLoading"><div></div><div></div><div></div><div></div></div>
          </div>
        </div>
      </div>
        <div class="col-9 zebra__track-info__wrap">
          <div key="1" class="zebra__track-info col-12">
                  <table class="wTable" v-if="tasks.length > 0">
                    <thead>
                      <tr class="wTable__th">
                        <td>Задача</td>
                        <td>Затречено</td>
                        <td>Коммент</td>
                        <td>Создан</td>
                      </tr>
                    </thead>
                    <tbody name="wTable" is="transition-group">
                      <template v-for="(task, key) in tasks">
                        <tr class="wTable__tr" :key="'task-' + key" v-if="Object.keys(tasks).length > 0">
                          <td class="">
                            <a
                              target="_blank"
                              class="wTable__project-link link-hover-animation"
                              :href="'https://tracker.yandex.ru/' + task['issue']"
                              >{{ task['issue'] }}</a
                            >
                          </td>
                          <td>
                            <span class="time">{{ formatTimeParts(task['duration'].hours) }}</span>:{{ formatTimeParts(task['duration'].minutes) }}
                          </td>
                          <td>
                            {{ task['comment'] }}
                          </td>
                          <td>
                            {{ task['createdAt'] }}
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                  <div class="col-12 zebra__track-info__no-info" v-else>
                    <p>Нет информации...</p>
                  </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'

export default {
  name: 'zebra-tasks-modal',
  props: {
    member: Object
  },
  data () {
    return {
      form: {
        dateRange: [
          moment().startOf('isoWeek').startOf('day'),
          moment().endOf('isoWeek').endOf('day')
        ],
        task: '',
        comment: ''
      },
      tasks: [],
      time: {
        overall: {
          minutes: 0,
          hours: 0
        },
        client: {
          minutes: 0,
          hours: 0
        }
      },
      requestLoading: false
    }
  },
  created () {
    this.searchTasks()
  },
  methods: {
    formatTimeParts (part) {
      const partOfTime = part.toString()
      return partOfTime.length === 1 ? '0' + partOfTime : partOfTime
    },
    searchTasks () {
      const member = this.member
      this.requestLoading = true
      const $this = this
      axios.get('/api/v1/yandex-tracker/search-tracked-tasks',
        {
          params: {
            member: member.username,
            from: moment(this.form.dateRange[0]).startOf('day').unix(),
            to: moment(this.form.dateRange[1]).endOf('day').unix(),
            task: this.form.task,
            comment: this.form.comment
          }
        }
      )
        .then((response) => {
          this.tasks = response.data.tasks.length > 0 ? response.data.tasks : []
          this.time = response.data.time
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(function () {
          $this.requestLoading = false
        })
    }
  },
  computed: {}
}
</script>
